import React, { useState } from 'react';
import axios from 'axios';
import {
  Container,
  ChatWindow,
  Messages,
  UserMessage,
  ChatbotMessage,
  InputContainer,
  Input,
  SendButton
} from './styles/AppStyles';

function App() {
  const [messages, setMessages] = useState([]);
  const [input, setInput] = useState('');

  const sendMessage = async (e) => {
    e.preventDefault();

    if (!input.trim()) return;

    setMessages([...messages, { role: 'user', content: input }]);
    setInput('');

    const { data } = await axios.post('https://langchain-4ccb6de995.wolf.jina.ai/hitl', {
      message: input,
      envs: {}
    }, {
      headers: {
        'Content-Type': 'application/json',
      }
    });

    setMessages((prevMessages) => [...prevMessages, { role: 'assistant', content: data.result }]);
  };

  return (
    <Container>
      <ChatWindow>
        <div style={{ padding: '20px', fontSize: '24px', fontWeight: 'bold' }}>TrấnThànhGPT - Your Gateway to Hào Quang Rực Rỡ 🌈⭐</div>
        <div style={{ paddingLeft: '20px', fontSize: '20px', fontWeight: 'bold' }}>Các skill chính của TrấnThànhGPT: </div>
        <li style={{ paddingLeft: '20px', paddingTop: '10px', fontSize:"12px"}}><b>Thành Dạy Hát</b>: Đỉnh hơn cả Bùi Anh Tuấn</li>
        <li style={{ paddingLeft: '20px', paddingTop: '10px', fontSize:"12px"}}><b>Thành Đạo Lí</b>: Lời nói của anh là chân lí</li>
        <li style={{ paddingLeft: '20px', paddingTop: '10px', fontSize:"12px"}}><b>Thành Nổi Tiếng</b>: Hào Quang Rực Rỡ</li>
        <li style={{ paddingLeft: '20px', paddingTop: '10px', fontSize:"12px"}}><b>Thành Kiện Cáo</b>: Chỉ khi bị mất hợp đồng quảng cáo</li>
        <li style={{ paddingLeft: '20px', paddingTop: '10px', fontSize:"12px"}}><b>Thành Riêng Tư</b>: Bao rạp</li>
        <p style={{ paddingLeft: '20px', fontSize:"10px"}}>Đôi lúc tôi nói hơi chậm (khoảng 1 phút) do tôi vừa [KHÓC] ... vừa nói! Mong quý vị thông cảm</p>
        <Messages>
          {messages.map((message, index) => (
            <React.Fragment key={index}>
              {message.role === 'user' ? (
                <UserMessage>{message.content}</UserMessage>
              ) : (
                <ChatbotMessage>{message.content}</ChatbotMessage>
              )}
            </React.Fragment>
          ))}
        </Messages>
        <InputContainer onSubmit={sendMessage}>
          <Input
            type="text"
            placeholder="Type your message..."
            value={input}
            onChange={(e) => setInput(e.target.value)}
          />
          <SendButton type="submit">Send</SendButton>
        </InputContainer>
      </ChatWindow>
    </Container>
  );
}

export default App;
