import styled from '@emotion/styled';
import { css, keyframes } from '@emotion/react';

const fadeIn = keyframes`
  from { opacity: 0; }
  to { opacity: 1; }
`;

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-height: 100vh;
  background-color: #f5f5f5;
`;

export const ChatWindow = styled.div`
  display: flex;
  flex-direction: column;
  max-width: 1200px;
  width: 100%;
  height: 80vh;
  background-color: white;
  border-radius: 10px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  overflow: hidden;

  @media (max-width: 1200px) {
    max-width: 700px;
  }

  @media (max-width: 800px) {
    max-width: 500px;
  }

  @media (max-width: 600px) {
    max-width: 300px;
  }
`;


export const Messages = styled.div`
  display: flex;
  flex-direction: column;
  padding: 20px;
  overflow-y: auto;
  flex: 1;
`;

export const Message = styled.div`
  margin-bottom: 10px;
  max-width: 70%;
  padding: 10px 15px;
  border-radius: 15px;
  animation: ${fadeIn} 0.4s linear;

  @media (max-width: 800px) {
    max-width: 100%;
    padding: 5px 10px;
  }
`;

export const UserMessage = styled(Message)`
  align-self: flex-end;
  background-color: #e2e2e2;
`;

export const ChatbotMessage = styled(Message)`
  align-self: flex-start;
  background-color: #6a9ff5;
  color: white;
`;

export const InputContainer = styled.form`
  display: flex;
  padding: 10px;
  background-color: #e2e2e2;

  @media (max-width: 800px) {
    padding: 5px;
  }
`;

export const Input = styled.input`
  flex: 1;
  padding: 10px;
  border: none;
  border-radius: 25px;
  outline: none;
`;

export const SendButton = styled.button`
  margin-left: 10px;
  padding: 10px;
  background-color: #6a9ff5;
  color: white;
  border: none;
  border-radius: 25px;
  cursor: pointer;
  transition: background-color 0.2s ease;

  &:hover {
    background-color: #4a7dd9;
  }
`;
